import { ReactComponent as GuardarIcon } from "../../assets/icons/guardar.svg";
import ModalSelectAuxNurse from "../modalSelectAuxNurse/modalSelectAuxNurse";
import { ReactComponent as CrossIcon } from "../../assets/icons/x.svg";
import { PatientViewContext } from "../../contexts/patientViewContext";
import { Drawer, CircularProgress } from "@mui/material";
import ModalSelectNurse from "../modalSelectNurse/modalSelectNurse";
import ModalCategory from "../modalCategory/modalCategory";
import { ISelectedPatient } from "../../store/interfaces";
import { useContext, useEffect, useState } from "react";
import { URI_API, getHeaders } from "../../utils/config";
import ModalNTISS from "../modalNTISS/modalNTISS";
import {
    AppState,
    deleteCategoryList,
    deletePatientCategoryId,
    deleteSelectedPatient,
} from "../../store/reducer";
import ModalTISS from "../modalTISS/modalTISS";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import "./patientForm.scss";
import "../modalStyles.scss";
import { Dispatch } from "redux";

export default function PatientForm(props: {
    showComponent: any;
    closeComponent: any;
    mode: any;
}) {
    const { selectedArea, setSelectedArea } = useContext(PatientViewContext);
    const [areas, setAreas] = useState<any>([]);

    const [patientRoom, setPatientRoom] = useState<string>("");

    useEffect(() => {
        const initialLoading = async () => {
            // Get users areas
            let areas = await (
                await fetch(`${URI_API}/session_user_areas`, getHeaders())
            ).json();
            setAreas(areas);
        };
        initialLoading();
    }, []);

    const [saveLoading, setSaveLoading] = useState(false);

    const savePatient = () => {
        setSaveLoading(true);
        let data = {};
        if (!props.mode) {
            data = {
                patient_number: selectedPatient.attentionId,
                name: selectedPatient.name,
                categoryid:
                    tissCount > 0
                        ? tissId
                        : ntissCount > 0
                        ? ntissId
                        : categoryId,
                categoryname:
                    tissCount > 0
                        ? `TISS: ${tissCount}`
                        : ntissCount > 0
                        ? `NTISS: ${ntissCount}`
                        : categoryDescription,
                room: selectedPatient.room,
                nurse_id: selectedPatient.nurse_id,
                secondaryNursesid: selectedPatient.secondarynurse_id,
                area_id: selectedArea,
                date_of_admission: selectedPatient.dateAdmission,
                checklist: categoryList.map((item: any) => ({
                    id: item.id,
                    checked: item.checked,
                })),

                id: selectedPatient.id,
                nursename: selectedPatient.nurseName,
                secondarynursename: selectedPatient.secondaryNurseName,
                areaname: areas.find((e: any) => e.area_id === selectedArea)
                    .area,
                tissvalue: tissCount || "0",
                ntissvalue: ntissCount || "0",
            };
        } else {
            data = {
                patient_number: selectedPatient.attentionId,
                name: selectedPatient.name,
                categoryid:
                    tissCount > 0
                        ? tissId
                        : ntissCount > 0
                        ? ntissId
                        : categoryId,
                categoryname:
                    tissCount > 0
                        ? `TISS: ${tissCount}`
                        : ntissCount > 0
                        ? `NTISS: ${ntissCount}`
                        : categoryDescription,
                room: selectedPatient.room,
                nurse_id: selectedPatient.nurse_id,
                secondaryNursesid: selectedPatient.secondarynurse_id,
                area_id: selectedArea,
                date_of_admission: selectedPatient.dateAdmission,
                checklist: categoryList.map((item: any) => ({
                    id: item.id,
                    checked: item.checked,
                })),
                id: selectedPatient.id,
                nursename: selectedPatient.nurseName,
                secondarynursename: selectedPatient.secondaryNurseName,
                areaname: areas.find((e: any) => e.area_id === selectedArea)
                    .area,
                tissvalue: tissCount || "0",
                ntissvalue: ntissCount || "0",
            };
        }

        fetch(`${URI_API}/patients`, getHeaders("PUT", data))
            .then((res) => res.json())
            .then((body) => {
                setSaveLoading(false);
                toast(props.mode ? "Paciente asignado" : "Paciente editado", {
                    type: "success",
                    containerId: "PV",
                });
                setSaveLoading(false);
                props.closeComponent();
            })
            .catch((e) => {
                toast("Error al editar paciente", {
                    type: "error",
                    containerId: "PV",
                });
                setSaveLoading(false);
            })
            .finally(() => {
                onClose();
            });
    };

    const showCategoryOption = () => {
        let tissOptions = ["CUIDADOS INTENSIVOS ADULTO", "UC INTERMEDIOS 2DO PISO", "CUIDADOS INTERMEDIOS ADULTO", "UNIDAD DE CUIDADOS INTENSIVOS ADULTOS", "UNIDAD DE CUIDADOS INTERMEDIOS ADULTOS", "UNIDAD DE CUIDADOS INTERMEDIOS"];
        let ntissOptions = ["CUIDADOS INTENSIVOS PEDIATRIA", "UNIDAD DE CUIDADOS INTENSIVOS NEONATALES Y PEDIATRICOS", "UNIDAD DE CUIDADOS INTENSIVOS NEONATALES", "UNIDAD DE CUIDADOS INTENSIVOS PEDIATRICOS"];
        let sf = areas.find((e: any) => e.area_id === selectedArea) || {
            area: "",
        };
        if (tissOptions.includes(sf.area)) {
            return "TISS";
        } else {
            if (ntissOptions.includes(sf.area)) {
                return "NTISS";
            } else {
                return "Categoria";
            }
        }
    };

    const showCategoryModalFunction = () => {
        let tissOptions = ["CUIDADOS INTENSIVOS ADULTO", "UC INTERMEDIOS 2DO PISO", "CUIDADOS INTERMEDIOS ADULTO", "UNIDAD DE CUIDADOS INTENSIVOS ADULTOS", "UNIDAD DE CUIDADOS INTERMEDIOS ADULTOS", "UNIDAD DE CUIDADOS INTERMEDIOS"];
        let ntissOptions = ["CUIDADOS INTENSIVOS PEDIATRIA", "UNIDAD DE CUIDADOS INTENSIVOS NEONATALES Y PEDIATRICOS", "UNIDAD DE CUIDADOS INTENSIVOS NEONATALES", "UNIDAD DE CUIDADOS INTENSIVOS PEDIATRICOS"];
        let sf = areas.find((e: any) => e.area_id === selectedArea) || {
            area: "",
        };
        if (tissOptions.includes(sf.area)) {
            return setShowTISSModal(true);
        } else {
            if (ntissOptions.includes(sf.area)) {
                return setShowNTISSModal(true);
            } else {
                return setShowCategoryModal(true);
            }
        }
    };

    const showCategoryLabel = () => {
        let tissOptions = ["CUIDADOS INTENSIVOS ADULTO", "UC INTERMEDIOS 2DO PISO", "CUIDADOS INTERMEDIOS ADULTO", "UNIDAD DE CUIDADOS INTENSIVOS ADULTOS", "UNIDAD DE CUIDADOS INTERMEDIOS ADULTOS", "UNIDAD DE CUIDADOS INTERMEDIOS"];
        let ntissOptions = ["CUIDADOS INTENSIVOS PEDIATRIA", "UNIDAD DE CUIDADOS INTENSIVOS NEONATALES Y PEDIATRICOS", "UNIDAD DE CUIDADOS INTENSIVOS NEONATALES", "UNIDAD DE CUIDADOS INTENSIVOS PEDIATRICOS"];
        let sf = areas.find((e: any) => e.area_id === selectedArea) || {
            area: "",
        };
        if (tissOptions.includes(sf.area)) {
            return tissCount > 0 ? `TISS: ${tissCount}` : "Seleccionar";
        } else {
            if (ntissOptions.includes(sf.area)) {
                return ntissCount > 0 ? `NTISS: ${ntissCount}` : "Seleccionar";
            } else {
                return categoryCount > 0 ? categoryDescription : "Seleccionar";
            }
        }
    };

    const onClose = (): void => {
        setCategoryCount(0);
        setTissCount(0);
        setNtissCount(0);
        setCategoryId("");
        setTissId("");
        setNtissId("");
        setCategoryDescription("");

        props.closeComponent();
        setCurrentKey(uuid());
        dispatch(deleteSelectedPatient());
        dispatch(deletePatientCategoryId());
        dispatch(deleteCategoryList());
    };

    const isNurseFieldDisabled = (): boolean => {
        return !(categoryCount > 0 || tissCount > 0 || ntissCount > 0);
    };

    const selectedPatient: ISelectedPatient = useSelector(
        (state: AppState) => state.selectedPatient
    );
    const categoryList = useSelector((state: AppState) => state.categoryList);

    const dispatch: Dispatch<any> = useDispatch();

    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [showSelectNurseModal, setShowSelectNurseModal] =
        useState<boolean>(false);
    const [showSelectAuxNurseModal, setShowSelectAuxNurseModal] =
        useState<boolean>(false);
    const [showTISSModal, setShowTISSModal] = useState<boolean>(false);
    const [showNTISSModal, setShowNTISSModal] = useState<boolean>(false);
    const [categoryCount, setCategoryCount] = useState<number>(0);
    const [tissCount, setTissCount] = useState<number>(0);
    const [ntissCount, setNtissCount] = useState<number>(0);
    const [categoryId, setCategoryId] = useState<string>("");
    const [tissId, setTissId] = useState<string>("");
    const [ntissId, setNtissId] = useState<string>("");
    const [categoryDescription, setCategoryDescription] = useState<string>("");
    const [currentKey, setCurrentKey] = useState<string>("");

    return (
        <div key={currentKey}>
            <ModalSelectNurse
                open={showSelectNurseModal}
                onClose={() => setShowSelectNurseModal(false)}
                onSave={() => {}}
            />
            <ModalSelectAuxNurse
                open={showSelectAuxNurseModal}
                onClose={() => setShowSelectAuxNurseModal(false)}
                onSave={() => {}}
            />
            <ModalCategory
                open={showCategoryModal}
                onClose={() => setShowCategoryModal(false)}
                onSave={(
                    categoryId: string,
                    count: number,
                    categoryDescription: string
                ) => {
                    setCategoryId(categoryId);
                    setCategoryCount(count);
                    setCategoryDescription(categoryDescription);
                }}
            />
            <ModalTISS
                open={showTISSModal}
                onClose={() => setShowTISSModal(false)}
                onSave={(tissId: string, count: number) => {
                    setTissId(tissId);
                    setTissCount(count);
                }}
            />
            <ModalNTISS
                open={showNTISSModal}
                onClose={() => setShowNTISSModal(false)}
                onSave={(ntissId: string, count: number) => {
                    setNtissId(ntissId);
                    setNtissCount(count);
                }}
            />

            <Drawer
                anchor="right"
                open={props.showComponent}
                PaperProps={{
                    sx: {
                        width: "22rem",
                        borderTopLeftRadius: "30px",
                        borderBottomLeftRadius: "30px",
                        overflowY: "hidden",
                    },
                }}
            >
                <div className="form-drawer__header">
                    <button className="button-icon" onClick={onClose}>
                        <CrossIcon fill="white"></CrossIcon>
                    </button>
                </div>
                <div className="form-drawer__header__title">
                    {props.mode ? "Asignar" : "Editar"} paciente
                </div>
                <div className="form-drawer__body">
                    <div className="form-drawer__body__field-container">
                        <span className="form-drawer__body__label">
                            Número de Expediente
                        </span>
                        <input
                            className="form-drawer__body__input form-drawer__body__input--disabled"
                            type="text"
                            disabled
                            value={selectedPatient.attentionId ?? ""}
                        ></input>
                    </div>
                    <div className="form-drawer__body__field-container">
                        <span className="form-drawer__body__label">
                            Nombre de Paciente
                        </span>
                        <input
                            type="text"
                            className="form-drawer__body__input form-drawer__body__input--disabled"
                            disabled
                            value={selectedPatient.name}
                        ></input>
                    </div>
                    <div className="form-drawer__body__field-container">
                        <span className="form-drawer__body__label">Sector</span>
                        <select
                            className="form-drawer__body__input"
                            style={{ cursor: "pointer" }}
                            value={selectedArea}
                            // TODO Cambiar este set al redux
                            onChange={(e) => setSelectedArea(e.target.value)}
                        >
                            <option value=""></option>
                            {areas
                                .sort((a: any, b: any) =>
                                    a.area.localeCompare(b.area)
                                )
                                .map((area: any) => {
                                    return (
                                        <option
                                            key={uuid()}
                                            value={area.area_id}
                                        >
                                            {area.area}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                    <div className="form-drawer__body__field-container">
                        <span className="form-drawer__body__label">
                            {showCategoryOption()}
                        </span>
                        <button
                            className="form-drawer__body__pill"
                            onClick={() => showCategoryModalFunction()}
                        >
                            {showCategoryLabel()}
                        </button>
                    </div>
                    <div className="form-drawer__body__field-container">
                        <span className="form-drawer__body__label">Cuarto</span>
                        <input
                            type="text"
                            className="form-drawer__body__input"
                            value={selectedPatient.room ?? ""}
                            // TODO Cambiar este set al redux
                            onChange={(e) => setPatientRoom(e.target.value)}
                        ></input>
                    </div>
                    <div className="form-drawer__body__field-container">
                        <span className="form-drawer__body__label">
                            Profesional
                        </span>
                        <button
                            disabled={isNurseFieldDisabled()}
                            className={`form-drawer__body__pill ${
                                showCategoryLabel() === "Seleccionar" &&
                                "form-drawer__body__pill--disabled"
                            }`}
                            onClick={() => setShowSelectNurseModal(true)}
                        >
                            {selectedPatient.nurse_id !== null
                                ? selectedPatient.nurseName
                                : "Seleccionar"}
                        </button>
                    </div>
                    <div className="form-drawer__body__field-container">
                        <span className="form-drawer__body__label">
                            Profesional Auxiliar
                        </span>

                        <button
                            disabled={isNurseFieldDisabled()}
                            className={`form-drawer__body__pill ${
                                showCategoryLabel() === "Seleccionar" &&
                                "form-drawer__body__pill--disabled"
                            }`}
                            onClick={() => setShowSelectAuxNurseModal(true)}
                        >
                            {selectedPatient.secondarynurse_id !== null
                                ? selectedPatient.secondaryNurseName
                                : "Seleccionar"}
                        </button>
                    </div>
                    <div className="form-drawer__body__field-container">
                        <span className="form-drawer__body__label">
                            Fecha de Ingreso
                        </span>
                        <input
                            type="text"
                            className="form-drawer__body__input form-drawer__body__input--disabled"
                            value={selectedPatient.dateAdmission}
                        ></input>
                    </div>
                </div>
                <div className="form-drawer__body__footer">
                    <button
                        className="form-drawer__body__footer__button"
                        onClick={() => {
                            onClose();
                            props.closeComponent();
                        }}
                    >
                        Cancelar
                    </button>
                    <button
                        className="form-drawer__body__footer__button--fill form-drawer__body__footer__button"
                        onClick={() => savePatient()}
                    >
                        {saveLoading ? (
                            <CircularProgress
                                sx={{
                                    color: "white",
                                }}
                                size={24}
                            ></CircularProgress>
                        ) : (
                            <>
                                <GuardarIcon></GuardarIcon>&nbsp;Asignar
                            </>
                        )}
                    </button>
                </div>
            </Drawer>
        </div>
    );
}
