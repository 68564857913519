import { OptionCategory } from "../interfaces/OptionCategory";
import {
    ILoggedUser,
    ISelectedPatient,
    emptyLoggedUser,
    emptySelectedPatient,
} from "./interfaces";
import { createSlice } from "@reduxjs/toolkit";

/**
 * Interface for AppState using in redux.
 *
 * Contains:
 *  - loggedUser: Information of current user logged.
 *  - selectedPatient: Information of a assigned or unassigned patient.
 *  - categoryList: Information of list of element selected of category. Used to assigned a patient.
 *  - isAuthenticated: Used to access to private routes.
 */
export interface AppState {
    loggedUser: ILoggedUser;
    selectedPatient: ISelectedPatient;
    categoryList: OptionCategory[];
    isAuthenticated: boolean;
}

const initialState: AppState = {
    loggedUser: emptyLoggedUser,
    selectedPatient: emptySelectedPatient,
    categoryList: [],
    isAuthenticated: false,
};

const appStateSlide = createSlice({
    name: "appState",
    initialState,
    reducers: {
        setLoggedUser(state, action) {
            state.loggedUser = action.payload;
        },

        deleteLoggedUser(state) {
            state.loggedUser = emptyLoggedUser;
        },

        setSelectedPatient(state, action) {
            state.selectedPatient = action.payload;
        },

        deleteSelectedPatient(state) {
            state.selectedPatient = emptySelectedPatient;
        },

        setPatientCategoryId(state, action) {
            state.selectedPatient.categoryId = action.payload;
        },

        deletePatientCategoryId(state) {
            state.selectedPatient.categoryId = null;
        },

        setPatientNurse(state, action) {
            state.selectedPatient.nurse_id = action.payload.nurse_id;
            state.selectedPatient.nurseName = action.payload.nurseName;
        },

        deletePatientNurse(state) {
            state.selectedPatient.nurse_id = null;
            state.selectedPatient.nurseName = null;
        },

        setPatientSecondaryNurse(state, action) {
            state.selectedPatient.secondarynurse_id =
                action.payload.secondarynurse_id;
            state.selectedPatient.secondaryNurseName =
                action.payload.secondaryNurseName;
        },

        deletePatientSecondaryNurse(state) {
            state.selectedPatient.secondarynurse_id = null;
            state.selectedPatient.secondaryNurseName = null;
        },

        setCategoryList(state, action) {
            state.categoryList = action.payload;
        },

        deleteCategoryList(state) {
            state.categoryList = [];
        },

        setAuthenticated(state, action) {
            state.isAuthenticated = action.payload;
        },
    },
});

export const {
    setLoggedUser,
    deleteLoggedUser,
    setSelectedPatient,
    deleteSelectedPatient,
    setPatientCategoryId,
    deletePatientCategoryId,
    setPatientNurse,
    deletePatientNurse,
    setPatientSecondaryNurse,
    deletePatientSecondaryNurse,
    setCategoryList,
    deleteCategoryList,
    setAuthenticated,
} = appStateSlide.actions;

export default appStateSlide.reducer;
