import { AssitanceAdjustViewContext } from "../../views/assistanceView/assistanceAdjustView";
import { useContext, useEffect, useState } from "react";
import "./deleteNurseModal.scss";

export default function DeleteNurseModal() {
    const { selectedNurses, nurses } = useContext(AssitanceAdjustViewContext);
    const [nursesNames, setNursesNames] = useState<string[]>([]);

    useEffect(() => {
        let nursesNameHelper: string[] = [];

        selectedNurses.map((nurse_id: string) => {
            const nurse = nurses.find((item: any) => item.id === nurse_id);
            nursesNameHelper.push(nurse.nursename);
        });

        setNursesNames(nursesNameHelper);
    }, [selectedNurses]);

    return (
        <div className="mainText">
            ¿Está seguro de que desea eliminar a los siguientes profesionales?
            {nursesNames.map((nurseName: string) => (
                <div>
                    <strong>{nurseName}</strong>
                </div>
            ))}
        </div>
    );
}
