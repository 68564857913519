import {
    Context,
    createContext,
    FC,
    ReducerWithoutAction,
    useReducer,
    useState,
} from "react";
import { IPatientViewContext } from "../interfaces/IPatientViewContext";

const GlobalState: IPatientViewContext = {
    query: "",
    setQuery: () => {},
    showNurseFunctionModal: false,
    setShowNurseFunctionModal: () => {},
    selectedPatient: {},
    setSelectedPatient: () => {},
    patientComments: [],
    setPatientComments: () => {},
    selectedArea: {},
    setSelectedArea: () => {},
    selectedUnassignedPatient: {},
    setSelectedUnassignedPatient: () => {},
    patients: [],
    setPatients: () => {},
};

const PatientViewContext: Context<IPatientViewContext> =
    createContext(GlobalState);

const PatientViewContainer: FC<{ children: JSX.Element }> = ({ children }) => {
    const [query, setQuery] = useState<string>("");
    const [showNurseFunctionModal, setShowNurseFunctionModal] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState<string>("");
    const [patientComments, setPatientComments] = useState<string[]>([]);
    const [selectedArea, setSelectedArea] = useState<string>("");
    const [selectedUnassignedPatient, setSelectedUnassignedPatient] =
        useState<any>({});
    const [patients, setPatients] = useState([]);
    return (
        <PatientViewContext.Provider
            value={{
                query,
                setQuery,
                showNurseFunctionModal,
                setShowNurseFunctionModal,
                selectedPatient,
                setSelectedPatient,
                patientComments,
                setPatientComments,
                selectedArea,
                setSelectedArea,
                selectedUnassignedPatient,
                setSelectedUnassignedPatient,
                patients,
                setPatients,
            }}
        >
            {children}
        </PatientViewContext.Provider>
    );
};
export { PatientViewContainer, PatientViewContext };
