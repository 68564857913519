import { AssitanceAdjustViewContext } from "../../views/assistanceView/assistanceAdjustView";
import { useContext, useEffect, useState } from "react";
import "./addNurseModal.scss";
import { Autocomplete, TextField } from "@mui/material";

export default function AddNurseModal() {
    const {
        availableNurses,
        availableAreas,
        selectedArea,
        setSelectedArea,
        selectedNurses,
        selectedNurse,
        onItemSelected,
        selectedShift,
        shifts,
    } = useContext(AssitanceAdjustViewContext);

    const [filteredAreas, setFilteredAreas] = useState([]);
    const [selectedNurse2, setSelectedNurse2] = useState<string>();

    useEffect(() => {
        let currentAreas = shifts.filter(
            (shift: any) => shift.key == selectedShift.key
        )[0].areas_id;

        setFilteredAreas(
            availableAreas
                .filter((item: any) => currentAreas.includes(item["id"]))
                .map((item: any) => ({
                    key: item["id"],
                    text: `${item["area"]}`,
                }))
        );
    }, []);

    useEffect(() => {
        if (selectedNurses.length === 1) setSelectedNurse2(selectedNurses[0]);
    }, [selectedNurses]);

    return (
        <>
            <p className="modal-input-title">Profesional *</p>
             <Autocomplete
                id="selectProfessional"
                options={availableNurses.sort((a: any, b: any) => a.name.localeCompare(b.name))}
                getOptionLabel={(option: any) => option.name}
                onChange={(e, newValue: any) => {
                    if (newValue != null) {
                        onItemSelected({id: newValue.id});
                    }
                }}
                size="small"
                renderInput={(params) => <TextField {...params} variant="outlined"/>} 
                value={availableNurses.find((nurse: any) => nurse.id === selectedNurse) || null }
            />
            <p className="modal-input-title">Categoría</p>
            <input
                disabled
                value={
                    availableNurses.find((e: any) => e.id === selectedNurse)
                        ? availableNurses.find(
                              (e: any) => e.id === selectedNurse
                          ).class
                        : ""
                }
                style={{
                    height: "5vh",
                    borderRadius: "3px",
                    backgroundColor: "#FFFFFF",
                    border: "1.5px solid #E6E6E6",
                    fontSize: '16px'
                }}
            ></input>
            <p className="modal-input-title">Sector *</p>
            <Autocomplete
                id="selectSector"
                options={filteredAreas.sort((a: any, b: any) => a.text.localeCompare(b.text))}
                getOptionLabel={(option: any) => option.text}
                onChange={(e, newValue: any) => {
                    if (newValue != null) {
                        setSelectedArea(newValue.key);
                    } else {
                        setSelectedArea("");
                    }
                }}
                size="small"
                renderInput={(params) => <TextField {...params} variant="outlined"/>} 
                value={filteredAreas.find((area: any) => area.key === selectedArea) || null}
            />
        </>
    );
}
