import { AssitanceAdjustViewContext } from "../../views/assistanceView/assistanceAdjustView";
import { ReactComponent as ReemplazarIcon } from "../../assets/icons/reemplazar-icon.svg";
import { getHeaders, URI_API } from "../../utils/config";
import { useContext, useEffect, useState } from "react";
import { Autocomplete, Box, Container, Stack, TextField } from "@mui/material";
import { v4 as uuid } from "uuid";
import "./modalStyles.scss";

export default function ReplaceNurseModal() {
    const {
        selectedNurses,
        setSelectedArea,
        selectedArea,
        availableAreas,
        nurses,
        replacementNurse,
        setReplacementNurse,
        selectedReason,
        setSelectedReason,
        selectedDate,
    } = useContext(AssitanceAdjustViewContext);

    const [actualNurse, setActualNurse] = useState<any>({});
    const [availableNurses, setAvailableNurses] = useState<any>([]);

    const changeReason = [
        {
            key: "Vacaciones",
            item: "Vacaciones",
        },
        {
            key: "Incapacidad",
            item: "Incapacidad",
        },
        {
            key: "Falta",
            item: "Falta",
        },
        {
            key: "Apoyo en área",
            item: "Apoyo en área",
        },
    ];

    useEffect(() => {
        setActualNurse(nurses.find((e: any) => e.id === selectedNurses[0]));
    }, [nurses, selectedNurses]);

    const onReplacementNurse = (nurse_id: string) => {
        setReplacementNurse(nurse_id);
        setSelectedArea(
            availableNurses.find((nurse: any) => nurse.nurse_id === nurse_id)
                ?.area_id
        );
    };

    useEffect(() => {
        fetch(
            `${URI_API}/nursing_calendar/${
                selectedDate.toISOString().split("T")[0]
            }`,
            getHeaders()
        )
            .then((res) => res.json())
            .then((res) => setAvailableNurses(res));
    }, []);

    return (
        <>
            <Container>
                <Stack direction={"row"}>
                    <Stack flex={4}>
                        <Box
                            marginTop={"2%"}
                            marginBottom={"1%"}
                            className="title-input"
                        >
                            Profesional
                        </Box>
                        <input
                            disabled
                            style={{
                                width: "100%",
                                height: "30px",
                                borderRadius: "3px",
                                backgroundColor: "#FFFFFF",
                                border: "2px solid #E6E6E6",
                            }}
                            value={actualNurse.nursename}
                        ></input>
                        <Box
                            marginTop={"10%"}
                            marginBottom={"1%"}
                            className="title-input"
                        >
                            Categoria
                        </Box>
                        <input
                            disabled
                            style={{
                                width: "100%",
                                height: "20px",
                                borderRadius: "3px",
                                backgroundColor: "#FFFFFF",
                                border: "2px solid #E6E6E6",
                            }}
                            value={actualNurse.classx}
                        ></input>
                        <Box
                            marginTop={"10%"}
                            marginBottom={"1%"}
                            className="title-input"
                        >
                            Sector
                        </Box>
                        <input
                            disabled
                            style={{
                                width: "100%",
                                height: "20px",
                                borderRadius: "3px",
                                backgroundColor: "#FFFFFF",
                                border: "2px solid #E6E6E6",
                            }}
                            value={actualNurse.area}
                        ></input>
                    </Stack>
                    <Stack flex={1} alignItems={"center"}>
                        <Box flex={2} width={"2px"} bgcolor={"#E0E0E0"}></Box>
                        <Stack flex={1}>
                            <ReemplazarIcon></ReemplazarIcon>
                        </Stack>
                        <Box flex={2} width={"2px"} bgcolor={"#E0E0E0"}></Box>
                    </Stack>
                    <Stack flex={4}>
                        <Box
                            marginTop={"2%"}
                            marginBottom={"1%"}
                            className="title-input"
                        >
                            Profesional
                        </Box>
                        <Autocomplete 
                        id="replaceAreaSelect"
                        options={
                            availableNurses
                            .sort((a: any, b: any) =>
                                a.nursename.localeCompare(b.nursename)
                            )}
                        getOptionLabel={(option: any) => option.nursename}
                        onChange={(e, newValue: any) => {
                                onReplacementNurse(newValue ? newValue.nurse_id : "");
                        }}
                        clearIcon={false}
                        size="small"
                        renderInput={(params) => <TextField 
                                                    sx={{ '& .MuiInputBase-input': {
                                                            fontSize: '13px',
                                                    }, }} 
                                                    {...params} 
                                                    variant="outlined"
                                                    />} 
                        value={availableNurses.find((nurse: any) => nurse.nurse_id === replacementNurse) || null}
                        />
                        <Box
                            marginTop={"10%"}
                            marginBottom={"1%"}
                            className="title-input"
                        >
                            Categoria
                        </Box>
                        <input
                            disabled
                            style={{
                                height: "20px",
                                borderRadius: "3px",
                                backgroundColor: "#FFFFFF",
                                border: "2px solid #E6E6E6",
                            }}
                            value={
                                availableNurses.find(
                                    (e: any) => e.nurse_id === replacementNurse
                                )?.classx ?? ""
                            }
                        ></input>
                        <Box
                            marginTop={"10%"}
                            marginBottom={"1%"}
                            className="title-input"
                        >
                            Sector
                        </Box>
                        <input
                            disabled
                            style={{
                                height: "20px",
                                borderRadius: "3px",
                                backgroundColor: "#FFFFFF",
                                border: "2px solid #E6E6E6",
                            }}
                            value={
                                availableAreas.find(
                                    (area: any) => area.id === selectedArea
                                )?.area ?? ""
                            }
                        ></input>
                    </Stack>
                </Stack>
                <Stack>
                    <Box
                        marginTop={"2%"}
                        marginBottom={"1%"}
                        className="title-input"
                    >
                        Razón de cambio
                    </Box>
                    <select
                        style={{
                            width: "100%",
                            height: "28px",
                            borderRadius: "3px",
                            backgroundColor: "#FFFFFF",
                            border: "2px solid #E6E6E6",
                        }}
                        onChange={(e: any) => {
                            setSelectedReason(e.target.value);
                        }}
                        value={selectedReason}
                    >
                        <option value=""></option>
                        {changeReason
                            .sort((a: any, b: any) =>
                                a.item.localeCompare(b.item)
                            )
                            .map((e: any) => {
                                return (
                                    <option key={uuid()} value={e.key}>
                                        {e.item}
                                    </option>
                                );
                            })}
                    </select>
                </Stack>
            </Container>
        </>
    );
}
