import { deleteLoggedUser, setAuthenticated } from "../../store/reducer";
import { ReactComponent as CrossIcon } from "../../assets/icons/x.svg";
import { getHeaders, URI } from "../../utils/config";
import { useMsal } from "@azure/msal-react";
import { Modal, Box } from "@mui/material";
import { useDispatch} from "react-redux";
import { Dispatch } from "redux";

export default function LogoutModal(props: {
    showComponent: boolean;
    closeComponent: any;
}) {

    const { instance } = useMsal();
    const dispatch: Dispatch<any> = useDispatch();

    const logout = (): void => {
        dispatch(deleteLoggedUser());
        dispatch(setAuthenticated(false));
        instance.logoutRedirect();
        fetch(`${URI}/logout`, getHeaders());
    };

    return (
        <Modal open={props.showComponent} onClose={props.closeComponent}>
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    width: window.innerHeight > 800 ? "25vw" : "30vw",
                    height: window.innerWidth > 1650 ? "30vh" : "40vh",
                    bgcolor: "#FFFFFF",
                    // border: "1px solid transparent",
                    boxShadow: 24,
                    // p: "2%",
                    borderRadius: "16px",
                }}
            >
                <div className="modalTitleContainerEdit">
                    <p className="modalTitle">Cerrar sesión</p>
                    <button
                        className="button-icon"
                        onClick={props.closeComponent}
                    >
                        <CrossIcon fill="white"></CrossIcon>
                    </button>
                </div>
                <div style={{ padding: "15px" }}>
                    <div
                        style={{
                            display: "flex",
                            fontFamily: "Manrope",
                            fontSize: "16px",
                            fontWeight: "700",
                            color: "#A2A2A2",
                            justifyContent: 'center',
                            marginTop: '8%'
                        }}
                    > ¿Está seguro de que desea cerrar sesión?
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: "5%",
                    }}
                >
                    <button
                        style={{
                            borderRadius: "16px",
                            border: "1px solid transparent",
                            backgroundColor: "transparent",
                            color: "#A2A2A2",
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: "14px",
                            lineHeight: "24px",
                            textAlign: "center",
                            padding: "1% 5% 1% 5%",
                            cursor: "pointer",
                        }}
                        type="button"
                        onClick={props.closeComponent}
                    >
                        CANCELAR
                    </button>
                    <button
                        style={{
                            borderRadius: "16px",
                            border: "1px solid #E6E6E6",
                            backgroundColor: "#62098C",
                            color: "#FFFFFF",
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: "14px",
                            lineHeight: "24px",
                            textAlign: "center",
                            padding: "1% 5% 1% 5%",
                            cursor: "pointer",
                            marginLeft: "2%",
                        }}
                        type="button"
                        onClick={logout}
                    >
                        Cerrar sesión
                    </button>
                </div>
            </Box>
        </Modal>
    );
}
