import ReactDOM from "react-dom/client";
import App from "./App";

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./utils/auth_config";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { createTheme } from "@mui/material";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }
    });

    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

    root.render(
        <HashRouter>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <App pca={msalInstance} />
                </PersistGate>
            </Provider>
        </HashRouter>
    );
});
