import { useContext, useState, useEffect } from "react";
import { Modal, Box } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { PatientViewContext } from "../../contexts/patientViewContext";
import humanized_time_span from "../../utils/time_span";
import { URI_API, getHeaders } from "../../utils/config";
import { ISelectedPatient } from "../../store/interfaces";
import { useDispatch, useSelector } from "react-redux";
import {
    AppState
} from "../../store/reducer";

export default function AddCommentModal(props: {
    showComponent: boolean;
    closeComponent: any;
}) {
    const { setPatientComments } =
        useContext(PatientViewContext);
    const selectedPatient: ISelectedPatient = useSelector(
        (state: AppState) => state.selectedPatient
    );
    const [commentsText, setCommentsText] = useState("");
    const sendPatientActivity = () => {
        fetch(
            `${URI_API}/comment/`,
            getHeaders("POST", {
                patientid: selectedPatient.id,
                activity_type: "comment",
                description: commentsText,
            })
        )
            .then((res) => {
                if (res.status === 200) {
                    toast("Comentario añadido", {
                        type: "success",
                        containerId: "ACM",
                    });
                    props.closeComponent();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetch(`${URI_API}/patient_activity/${selectedPatient.id}`, getHeaders())
            .then((res) => res.json())
            .then((body) => {
                let data = body.map((item: any) => {
                    let description = "actualizo el paciente:";
                    if (item.activity_type === "comment")
                        description = "comentó:";
                    item.time = 
                        item.date + " " + item.time
                    return item;
                });
                setPatientComments(data);
            });
    }, [selectedPatient, props.showComponent]);

    return (
        <>
            <Modal
                open={props.showComponent}
                onClose={() => props.closeComponent()}
            >
                <Box
                    sx={{
                        position: "relative",
                        top: "25%",
                        left: "53%",
                        width: "25%",
                        height: "auto",
                        bgcolor: "#FFFFFF",
                        boxShadow: 24,
                        borderRadius: "15px",
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "#62098C",
                            justifyContent: "start",
                            padding: "8%",
                            borderTopLeftRadius: "15px",
                            borderTopRightRadius: "15px",
                            height: "10%",
                        }}
                    >
                        <div
                            style={{
                                color: "#FFFFFF",
                                paddingLeft: "5%",
                                fontFamily: "Manrope",
                                fontWeight: "700",
                                fontSize: "14px",
                            }}
                        >
                            Agregar commentario
                        </div>
                    </div>
                    <div style={{ margin: "8%" }}>
                        <div className="CommentsText">
                            <span
                                style={{
                                    fontWeight: 700,
                                    color: "#797979",
                                    fontSize: "14px",
                                }}
                            >
                                id:{" "}
                            </span>
                            {selectedPatient.attentionId}
                        </div>
                        <div
                            className="CommentsText"
                            style={{ marginBottom: "5%" }}
                        >
                            <span
                                style={{
                                    fontWeight: 700,
                                    color: "#797979",
                                    fontSize: "14px",
                                    textTransform: "capitalize",
                                }}
                            >
                                nombre:{" "}
                            </span>
                            {selectedPatient.name}
                        </div>
                        <textarea
                            className="CommentsInput"
                            cols={36}
                            rows={10}
                            onChange={(e) =>
                                setCommentsText(e.currentTarget.value)
                            }
                        ></textarea>
                    </div>
                    <div className="BtnComm" style={{ display: "flex" }}>
                        <button
                            onClick={() => props.closeComponent()}
                            style={{
                                border: "transparent",
                                borderRadius: "24px",
                                backgroundColor: "transparent",
                                height: "30px",
                                width: "40%",
                                cursor: "pointer",
                                color: "#A1A1A1",
                                margin: "3%",
                                textTransform: "uppercase",
                            }}
                        >
                            Cancelar
                        </button>
                        <button
                            onClick={() => sendPatientActivity()}
                            style={{
                                border: "1px solid #CDCDCD",
                                borderRadius: "24px",
                                backgroundColor: "#62098C",
                                height: "30px",
                                width: "30%",
                                cursor: "pointer",
                                color: "#FFFFFF",
                                margin: "3%",
                            }}
                        >
                            Aceptar
                        </button>
                    </div>
                </Box>
            </Modal>
            <ToastContainer
                enableMultiContainer
                containerId={"ACM"}
                autoClose={2000}
                position="top-right"
            ></ToastContainer>
        </>
    );
}
