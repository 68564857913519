import { AssitanceAdjustViewContext } from "../../views/assistanceView/assistanceAdjustView";
import { ReactComponent as ReemplazarIcon } from "../../assets/icons/reemplazar-icon.svg";
import { useContext, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

export default function MoveNurseModal() {
    const [actualNurse, setActualNurse] = useState<any>({});
    const {
        selectedNurses,
        nurses,
        availableAreas,
        setSelectedArea,
        selectedArea,
        selectedReason,
        setSelectedReason,
    } = useContext(AssitanceAdjustViewContext);

    const changeReason = [
        {
            key: "Vacaciones",
            item: "Vacaciones",
        },
        {
            key: "Incapacidad",
            item: "Incapacidad",
        },
        {
            key: "Falta",
            item: "Falta",
        },
        {
            key: "Apoyo en área",
            item: "Apoyo en área",
        },
    ];

    useEffect(() => {
        setActualNurse(nurses.find((e: any) => e.id === selectedNurses[0]));
    }, [selectedNurses]);

    return (
        <div>
            <div
                style={{
                    marginTop: "2%",
                    marginBottom: "1%",
                    fontFamily: "Manrope",
                    fontWeight: "700",
                    fontSize: "12px",
                }}
            >
                Profesional
            </div>
            <input
                disabled
                style={{
                    width: "100%",
                    height: "5vh",
                    borderRadius: "3px",
                    backgroundColor: "#FFFFFF",
                    border: "3px solid #E6E6E6",
                    fontSize: "16px"
                }}
                value={actualNurse?.nursename}
            ></input>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginTop: "3%",
                }}
            >
                <div
                    style={{
                        flex: 6,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            marginTop: "2%",
                            marginBottom: "1%",
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: "12px",
                        }}
                    >
                        Sector Actual
                    </div>
                    <input
                        disabled
                        style={{
                            width: "100%",
                            height: "5vh",
                            borderRadius: "3px",
                            backgroundColor: "#FFFFFF",
                            border: "3px solid #E6E6E6",
                            fontSize: "16px"
                        }}
                        value={actualNurse?.area}
                    ></input>
                </div>
                <div
                    style={{
                        flex: 1,
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ReemplazarIcon></ReemplazarIcon>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 8,
                    }}
                >
                    <div
                        style={{
                            marginTop: "2%",
                            marginBottom: "1%",
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: "12px",
                        }}
                    >
                        Sector Nuevo *
                    </div>
                    <Autocomplete 
                        id="selectNewSector"
                        options={
                            availableAreas
                            .sort((a: any, b: any) =>
                                a.area.localeCompare(b.area)
                            )}
                        getOptionLabel={(option: any) => option.area}
                        onChange={(e, newValue: any) => {
                            if (newValue != null) {
                                setSelectedArea(newValue.id);
                            }
                        }}
                        size="small"
                        sx={{ width: "100%"}}
                        renderInput={(params) => <TextField {...params} variant="outlined"/>} 
                        value={availableAreas.find((area: any) => area.id === selectedArea) || null}
                    />
                    {/* <select
                        style={{
                            width: "100%",
                            height: "28px",
                            borderRadius: "3px",
                            backgroundColor: "#FFFFFF",
                            border: "3px solid #E6E6E6",
                        }}
                        onChange={(e) => {
                            setSelectedArea(e.target.value);
                        }}
                        value={selectedArea}
                    >
                        <option value=""></option>
                        {availableAreas
                            .sort((a: any, b: any) =>
                                a.area.localeCompare(b.area)
                            )
                            .map((e: any) => {
                                return <option value={e.id}>{e.area}</option>;
                            })}
                    </select> */}
                </div>
            </div>
            <div
                style={{
                    marginTop: "2%",
                    marginBottom: "1%",
                    fontFamily: "Manrope",
                    fontWeight: "700",
                    fontSize: "12px",
                }}
            >
                Razon de Cambio
            </div>
            <select
                style={{
                    width: "100%",
                    height: "5vh",
                    borderRadius: "3px",
                    backgroundColor: "#FFFFFF",
                    border: "3px solid #E6E6E6",
                    fontSize: "16px"
                }}
                onChange={(e: any) => {
                    setSelectedReason(e.target.value);
                }}
                value={selectedReason}
            >
                <option value=""></option>
                {changeReason
                    .sort((a: any, b: any) => a.item.localeCompare(b.item))
                    .map((e: any) => {
                        return <option value={e.key}>{e.item}</option>;
                    })}
            </select>
        </div>
    );
}
