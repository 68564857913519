import { ReactComponent as CommentPatientIcon } from "../assets/icons/commentPatient.svg";
import { ReactComponent as EditPatientIcon } from "../assets/icons/editPatient.svg";
import { ReactComponent as AltaPatientIcon } from "../assets/icons/altaPatient.svg";
import { PatientViewContext } from "../contexts/patientViewContext";
import { useContext, useEffect, useState } from "react";
import { LinearProgress, Tooltip } from "@mui/material";
import { URI_API, getHeaders } from "../utils/config";
import { AppState, setSelectedPatient } from "../store/reducer";
import { useDispatch, useSelector } from "react-redux";
import "./patientsTable.scss";
import { v4 as uuid } from "uuid";
import { emptySelectedPatient, ILoggedUser } from "../store/interfaces";
import { Dispatch } from "redux";

export default function PatientsTable(props: {
    patientForm: any;
    drawerController: any;
    paController: any;
    altaModalVisible: any;
    setAltaModalVisible: any;
}) {
    const { setSelectedArea, query, patients, setPatients } =
        useContext(PatientViewContext);

    const [tableLoading, setTableLoading] = useState<boolean>(true);

    const isAuthenticated: boolean = useSelector(
        (state: AppState) => state.isAuthenticated
    );

    const loggedUser: ILoggedUser = useSelector(
        (state: AppState) => state.loggedUser
    );

    const dispatch: Dispatch<any> = useDispatch();

    useEffect(() => {
        if (isAuthenticated)
            fetch(`${URI_API}/patients`, getHeaders())
                .then((res) => res.json())
                .then((body) => {
                    setPatients(body);
                    setTableLoading(false);
                });
    }, [props.altaModalVisible, props.patientForm, isAuthenticated]);

    function mergeObjects(obj1: any, obj2: any): any {
        const mergedObj = { ...obj1 };

        for (const key in obj2) {
            if (obj2.hasOwnProperty(key)) {
                if (obj2[key] !== null && obj2[key] !== "") {
                    mergedObj[key] = obj2[key];
                }
            }
        }

        return mergedObj;
    }

    const selectPatient = (patient: any): void => {
        let selectedPatient: any = {
            id: patient["id"],
            name: patient["name"],
            attentionId: patient["patient_number"],
            room: patient["room"],
            dateAdmission: patient["date_of_admission"],
            area_id: patient["area_id"],
            areaName: patient["areaname"],
            nurseName: patient.nursename,
            secondaryNurseName: patient.secondarynursename
        };
        selectedPatient = mergeObjects(emptySelectedPatient, selectedPatient);
        dispatch(setSelectedPatient(selectedPatient));
    };

    const tissOptions = ["CUIDADOS INTENSIVOS ADULTO", "UC INTERMEDIOS 2DO PISO", "CUIDADOS INTERMEDIOS ADULTO", "UNIDAD DE CUIDADOS INTENSIVOS ADULTOS", "UNIDAD DE CUIDADOS INTERMEDIOS ADULTOS", "UNIDAD DE CUIDADOS INTERMEDIOS"];
    const ntissOptions = ["CUIDADOS INTENSIVOS PEDIATRIA", "UNIDAD DE CUIDADOS INTENSIVOS NEONATALES Y PEDIATRICOS", "UNIDAD DE CUIDADOS INTENSIVOS NEONATALES", "UNIDAD DE CUIDADOS INTENSIVOS PEDIATRICOS"];
                                        

    return (
        <>
            {!tableLoading && (
                <table className="mainTable">
                    <tbody>
                        {patients
                            // .sort((a, b) => {
                            //     const stringA = a.date_of_admission
                            //         .split("/")
                            //         .reverse();
                            //     const stringB = b.date_of_admission
                            //         .split("/")
                            //         .reverse();
                            //     const fechaA = new Date(
                            //         parseInt(stringA[0]) > 2000
                            //             ? parseInt(stringA[0])
                            //             : parseInt(stringA[0]) + 2000,
                            //         parseInt(stringA[1]),
                            //         parseInt(stringA[2])
                            //     );
                            //     const fechaB = new Date(
                            //         parseInt(stringB[0]) > 2000
                            //             ? parseInt(stringB[0])
                            //             : parseInt(stringB[0]) + 2000,
                            //         parseInt(stringB[1]),
                            //         parseInt(stringB[2])
                            //     );
                            //     return fechaB.getTime() - fechaA.getTime();
                            // })
                            .sort((a: any, b: any) => {
                                if (typeof a.room === 'number' && typeof b.room === 'number') {
                                    return a.room - b.room; 
                                }
                                if (typeof a === 'number') return -1;
                                if (typeof b === 'number') return 1;  
                                return a.room.localeCompare(b.room); 
                            })
                            .filter((patient) => {
                                if (query === "") {
                                    return patient;
                                } else if (
                                    patient.name
                                        .toUpperCase()
                                        .includes(query.toUpperCase()) ||
                                    (patient.room && patient.room
                                        .toString()
                                        .toUpperCase()
                                        .includes(query.toUpperCase())) ||
                                    patient.areaname
                                        .toUpperCase()
                                        .includes(query.toUpperCase())
                                ) {
                                    return patient;
                                }
                            })
                            .map((patient) => {
                                return (
                                    <tr key={uuid()}>
                                        <td className="td-content" style={{
                                                width: "7%",
                                            }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row'
                                            }}>
                                                <Tooltip
                                                    title="Editar paciente"
                                                    placement="top"
                                                    arrow
                                                >
                                                    <button
                                                        className="button-icon"
                                                        onClick={() => {
                                                            selectPatient(
                                                                patient
                                                            );
                                                            setSelectedArea(
                                                                patient.area_id
                                                            );
                                                            props.drawerController();
                                                        }}
                                                    >
                                                        <EditPatientIcon></EditPatientIcon>
                                                    </button>
                                                </Tooltip>
                                                <Tooltip
                                                    title="Actividad del paciente"
                                                    placement="top"
                                                    arrow
                                                >
                                                    <button
                                                        className="button-icon"
                                                        onClick={() => {
                                                            selectPatient(
                                                                patient
                                                            );
                                                            setSelectedArea(
                                                                patient.area_id
                                                            );
                                                            props.paController();
                                                        }}
                                                    >
                                                        <CommentPatientIcon></CommentPatientIcon>
                                                    </button>
                                                </Tooltip>
                                                <Tooltip
                                                    title="Dar de alta"
                                                    placement="top"
                                                    arrow
                                                >
                                                    <button
                                                        className="button-icon"
                                                        onClick={() => {
                                                            selectPatient(
                                                                patient
                                                            );
                                                            props.setAltaModalVisible(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        <AltaPatientIcon></AltaPatientIcon>
                                                    </button>
                                                </Tooltip>
                                            </div>
                                        </td>
                                        <td className="td-content"
                                            style={{
                                                width: "6%",
                                            }}
                                        >
                                            {patient.patient_number}
                                        </td>
                                        <td className="td-content" style={{ width: "17%" }}>
                                            {patient.name}
                                        </td>
                                        <td className="td-content" style={{ width: "12%" }}>
                                            {patient.areaname}
                                        </td>
                                        <td className="td-content" style={{ width: "5%" }}>
                                            {patient.room}
                                        </td>
                                        {loggedUser.position === "nura_super" && (
                                            <td className="td-content" style={{ width: "7%" }}>
                                                {patient.hospital_name}
                                            </td>
                                        )}
                                        {tissOptions.includes(patient.areaname) && 
                                            <td className="td-content" style={{ width: "10%" }}>
                                                {'TISS ' + patient.tiss_value}
                                            </td> 
                                        } 
                                        {ntissOptions.includes(patient.areaname) &&
                                            <td className="td-content" style={{ width: "10%" }}>
                                                {'NTISS ' + patient.ntiss_value}
                                            </td> 
                                        }
                                        {(!tissOptions.includes(patient.areaname) && !ntissOptions.includes(patient.areaname)) &&
                                            <td className="td-content" style={{ width: "10%" }}>
                                                {patient.categoryname}
                                            </td>
                                        }
                                        <td className="td-content" style={{ width: "12.5%" }}>
                                            {patient.nursename}
                                        </td>
                                        <td className="td-content"
                                            style={{
                                                width: "16%",
                                                textAlign: "left",
                                                opacity:
                                                    patient.secondarynursename !==
                                                    null
                                                        ? "1"
                                                        : "0.7",
                                                userSelect:
                                                    patient.secondarynursename !==
                                                    null
                                                        ? "text"
                                                        : "none",
                                            }}
                                        >
                                            {patient.secondarynursename !== null
                                                ? patient.secondarynursename
                                                : "Sin profesional auxiliar"}
                                        </td>
                                        <td className="td-content">{patient.date_of_admission}</td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            )}
            {tableLoading && (
                <LinearProgress
                    color="secondary"
                    style={{
                        marginTop: "1%",
                    }}
                ></LinearProgress>
            )}
        </>
    );
}
