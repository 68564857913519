import { ReactComponent as PlanificationIcon } from "../../assets/icons/planification-icon.svg";
import { ReactComponent as AdjustIcon } from "../../assets/icons/assistance-adjust-icon.svg";
import { ReactComponent as PatientIcon } from "../../assets/icons/patient-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout-icon.svg";
import { ReactComponent as ReportIcon } from "../../assets/icons/report-icon.svg";
import { ReactComponent as ManualIcon } from "../../assets/icons/manual-icon.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { URI, getHeaders } from "../../utils/config";
import { useMsal } from "@azure/msal-react";
import { motion } from "framer-motion";
import "./sidenav.scss";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { deleteLoggedUser, setAuthenticated } from "../../store/reducer";
import { useState } from "react";
import LogoutModal from "../logoutModal/logoutModal";

const SideNav = () => {
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    let navigator = useNavigate();
    const location = useLocation();

    const dispatch: Dispatch<any> = useDispatch();

    const { instance } = useMsal();

    const logout = (): void => {
        dispatch(deleteLoggedUser());
        dispatch(setAuthenticated(false));
        instance.logoutRedirect();
        fetch(`${URI}/logout`, getHeaders());
    };

    const redirectToManual = () => {
        window.open("https://www.canva.com/design/DAF6KQdUuyc/n9WGkap0ln-qqLGiinUXcw/view?utm_content=DAF6KQdUuyc&utm_campaign=designshare&utm_medium=link&utm_source=editor", "_blank");
    };

    const redirectToForms = () => {
        window.open("https://www.canva.com/design/DAGUULVPVvU/OkpuMyND6mP0eJkOv9I1zQ/view?utm_content=DAGUULVPVvU&utm_campaign=designshare&utm_medium=link&utm_source=editor", "_blank");
    };

    const menuOptions = [
        {
            label: "Pacientes",
            pathname: "/patient",
            to: "patient",
            icon: (
                <PatientIcon
                    className={
                        location.pathname === "/patient"
                            ? "menu-button__icon-stroke--selected"
                            : "menu-button__icon-stroke"
                    }
                ></PatientIcon>
            ),
        },
        {
            label: "Ajustes de Asistencia",
            pathname: "/adjust",
            to: "adjust",
            icon: (
                <AdjustIcon
                    className={
                        location.pathname === "/adjust"
                            ? "menu-button__icon-stroke--selected"
                            : "menu-button__icon-stroke"
                    }
                ></AdjustIcon>
            ),
        },
        {
            label: "Planificacion de asistencia",
            pathname: "/planification",
            to: "planification",
            icon: (
                <PlanificationIcon
                    className={
                        location.pathname === "/planification"
                            ? "menu-button__icon-stroke--selected"
                            : "menu-button__icon-stroke"
                    }
                ></PlanificationIcon>
            ),
        },
    ];

    const navigateTo = (route: string): void => {
        navigator(`/${route}`);
    };

    return (
        <>
        <motion.div
            whileHover={{
                width: "15%",
                transition: {
                    ease: "easeInOut",
                },
            }}
            className="sidenav"
        >
            <div className="sidenav__logo">
                <div className="sidenav__logo-container">
                    <img
                        width={65}
                        alt="Nurasign"
                        src={require("../../assets/images/nurasign_blanco.png")}
                    ></img>
                </div>
            </div>
            {menuOptions.map((options) => {
                return (
                    <button
                        className={
                            location.pathname === options.pathname
                                ? "menu-button--selected"
                                : "menu-button"
                        }
                        onClick={(e) => navigateTo(options.to)}
                    >
                        <div className="menu-button__icon">{options.icon}</div>
                        <div className="menu-button__label">
                            {options.label}
                        </div>
                    </button>
                );
            })}
            <div
                style={{
                    flex: 0.96,
                }}
            ></div>
            <button className="menu-button" onClick={redirectToManual}>
                <div className="menu-button__icon">
                    <ManualIcon className="menu-button__icon--filled"></ManualIcon>
                </div>
                <div className="menu-button__label">Manual de Usuario</div>
            </button>
            <button className="menu-button" onClick={redirectToForms}>
                <div className="menu-button__icon">
                    <ReportIcon className="menu-button__icon--filled"></ReportIcon>
                </div>
                <div className="menu-button__label">Reportar incidencia</div>
            </button>
            <button className="menu-button" onClick={() => setShowLogoutModal(true)}>
                <div className="menu-button__icon">
                    <LogoutIcon className="menu-button__icon--filled"></LogoutIcon>
                </div>
                <div className="menu-button__label">Cerrar Sesión</div>
            </button>
        </motion.div>
        <LogoutModal showComponent={showLogoutModal} closeComponent={() => setShowLogoutModal(false)}></LogoutModal>
        </>
    );
};

export default SideNav;
