import { ReactComponent as ProfesionalsIcon } from "../../assets/icons/pros.svg";
import { ReactComponent as GuardarIcon } from "../../assets/icons/guardar.svg";
import { ReactComponent as BackIcon } from "../../assets/icons/back.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/x.svg";
import PatientShiftTable from "../../widgets/patientShiftTable";
import { useState, useEffect, createContext } from "react";
import HeaderComponent from "../../widgets/header/header";
import { ISelectedPatient } from "../../store/interfaces";
import { URI_API, getHeaders } from "../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Shift from "../../utils/shiftFunctions";
import { Backdrop, CircularProgress, Drawer } from "@mui/material";
import { v4 as uuid } from "uuid";
import "./changeShiftView.scss";

export const ChangeShiftViewContext = createContext<any>({
    patients: [],
    shifts: [],
    matrix: [],
    availableNurses: [],
    setPatients: () => {},
    setAvailableNurses: () => {},
    query: "",
    changedNurses: {},
    setChangedNurses: () => {},
    changedSecondaryNurses: {},
    setChangedSecondaryNurses: () => {},
});

export default function ChangeShiftView() {
    const [shifts, setShifts] = useState<any>([]);
    const [patients, setPatients] = useState<ISelectedPatient[]>([]);

    const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
    const [matrix, setMatrix] = useState<any>([]);
    const [areas, setAreas] = useState<any>([]);
    const [selectedArea, setSelectedArea] = useState<any>("");
    const [selectedShift, setSelectedShift] = useState<any>("");
    const [availableNurses, setAvailableNurses] = useState<any>([]);
    const [allPatients, setAllPatients] = useState<any>([]);
    const [query, setQuery] = useState("");

    // Used to detect new assign nurses and secondaryNurses to patients.
    const [changedNurses, setChangedNurses] = useState<any>({});
    const [changedSecondaryNurses, setChangedSecondaryNurses] = useState<any>(
        {}
    );

    // Open Modal
    // const [showProfessionalModal, setShowProfessionalModal] = useState(false);
    let navigator = useNavigate();

    const canSave = () => {
        return (
            Object.keys(changedNurses).length !== 0 ||
            Object.keys(changedSecondaryNurses).length !== 0
        );
    };

    const getNursesByDay = (shift: any, area: any) => {
        setShowBackdrop(true);
        let date = new Date();
        const hours = date.getHours();
        let date_str: any = new Date(
            date.getFullYear() +
                "-" +
                (date.getMonth() + 1) +
                "-" +
                date.getDate()
        );
        if (hours >= 0 && hours < 7 && shift.key === "nocturno") {
            const day = 1000 * 60 * 60 * 24;
            const yesterday = new Date(date_str.getTime() - day);
            date_str = yesterday.toISOString().slice(0, 10);
        } else {
            date_str = date_str.toISOString().slice(0, 10);
        }
        if (!shift || !area) {
            //alert("Hace falta un turno o sector asignado, favor de contactar a TI o Administración")
            return null;
        }
        fetch(
            `${URI_API}/nursing_calendar_sch/${date_str}/${shift.key}/${area.key}`,
            getHeaders()
        )
            .then((res) => res.json())
            .then((res) => {
                let nurses = res.map((n: any) => ({
                    ...n,
                    get patientcount() {
                        return patients.length;
                    },
                }));
                const idList: any = [];
                nurses = nurses.reduce((agg: any, curr: any) => {
                    if (idList.indexOf(curr.nurse_id) < 0) {
                        agg.push(curr);
                        idList.push(curr.nurse_id);
                    }
                    return agg;
                }, []);
                setAvailableNurses(
                    nurses.sort(
                        (a: any, b: any) => a.patientcount - b.patientcount
                    )
                );
                // if (filterPatients) {
                // setPatients([...filterPatients]);
                // }
            })
            .then(()=> setShowBackdrop(false));
    };

    const getAllWorkScheduleNames = async () => {
        let res = await (
            await fetch(`${URI_API}/work_schedule_names`, getHeaders())
        ).json();

        let currentShift = Shift.AsOption(Shift.Current(res));
        setShifts(res);
        setSelectedShift(currentShift);
        return currentShift;
    };

    const getClassCategoryMatrix = async () => {
        let res = await (
            await fetch(`${URI_API}/class_category_matrix`, getHeaders())
        ).json();
        setMatrix(res);
    };

    const getLoggedUserAreas = async () => {
        let res = await (
            await fetch(`${URI_API}/areas_per_user`, getHeaders())
        ).json();

        let availableAreas = res
            .filter((area: any) => area.can_update)
            .map((area: any) => ({ key: area.id, text: `${area.area}` }));
        availableAreas = [{ key: "all", text: "Todos" }, ...availableAreas];
        setAreas(availableAreas);
        setSelectedArea(availableAreas[0]);
        return availableAreas[0];
    };

    const getPatients = async () => {
        const res = await (
            await fetch(`${URI_API}/patients`, getHeaders())
        ).json();

        const nurse_history_res = await (
            await fetch(`${URI_API}/patients/nurse_history`, getHeaders())
        ).json();

        let patients = res.sort((a: any, b: any) => a.id.localeCompare(b.id));

        patients = patients.map((p: any) => {
            let nurse_history = nurse_history_res
                .filter((pn: any) => pn.patientid === p.id)
                .sort((a: any, b: any) =>
                    a.created_date.localeCompare(b.created_date)
                );
            let patient: ISelectedPatient = {
                id: p.id,
                name: p.name,
                dateAdmission: p.date_of_admission,
                dateEgress: p.egress_date,
                room: p.room,
                categoryId: p.categoryid,
                categoryName: p.categoryname,
                categoryMaxValue: p.categorymaxvalue,
                area_id: p.area_id,
                areaName: p.areaname,
                nurseHistory: nurse_history,
                nurse_id: p.nurse_id,
                nurseName: p.nursename,
                attentionId: p.patient_number,
                secondarynurse_id: p.secondary_nurse_id,
                secondaryNurseName: p.secondarynursename,
            };
            return patient;
        });

        setPatients(patients);
        setAllPatients(patients);
    };

    useEffect(() => {
        setShowBackdrop(true);
        Promise.all([
            getAllWorkScheduleNames(),
            getClassCategoryMatrix(),
            getLoggedUserAreas(),
            getPatients(),
        ]).then((values: any) => {
            getNursesByDay(values[0], values[2]);
        });
    }, []);

    const onSave = () => {
        setShowBackdrop(true);
        const data = patients.map((p: any) => ({
            id: p.id,
            nurse_id: p.nurse_id,
            secondarynurse_id: p.secondarynurse_id,
        })); 
        fetch(`${URI_API}/patient_nurses`, getHeaders("PUT", data))
            .then((res) => res.json())
            .then((body) => {
                toast("Actividad registrada", {
                    type: "success",
                    containerId: "CSV",
                });
            }
        ).then(() => setShowBackdrop(false));
    };

    const dt_options: any = {
        timeZone: "America/Monterrey",
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
    };

    return (
        <ChangeShiftViewContext.Provider
            value={{
                patients,
                shifts,
                matrix,
                availableNurses,
                setPatients,
                setAvailableNurses,
                query,
                changedNurses,
                setChangedNurses,
                changedSecondaryNurses,
                setChangedSecondaryNurses,
            }}
        >
            <>
                <div style={{ marginLeft: "10%" }}>
                    <HeaderComponent showSearch={false}></HeaderComponent>
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                            <div className="ParagraphText">
                                <button
                                    className="button-icon"
                                    onClick={() => navigator("/patient")}
                                >
                                    <BackIcon
                                        style={{ marginRight: "10px", marginBottom: '25%' }}
                                    ></BackIcon>
                                </button>
                                Cambio de turno
                            </div>
                            <div className="FridayText">
                                {new Date().toLocaleTimeString(
                                    "es-MX",
                                    dt_options
                                )}
                            </div>
                        </div>
                        {/* <div style={{ flex: 1 }}>
                            <button
                                className="ProffesionalBtn"
                                onClick={() => setShowProfessionalModal(true)}
                            >
                                <ProfesionalsIcon
                                    style={{ marginRight: "10px" }}
                                ></ProfesionalsIcon>
                                Profesionales en turno
                            </button>
                        </div> */}
                    </div>
                    <div style={{ display: "flex" }}>
                        <div>
                            <input
                                type="text"
                                name="search"
                                placeholder="Buscar"
                                className="SearchInput"
                                value={query}
                                onChange={(e: any) => setQuery(e.target.value)}
                            ></input>
                        </div>
                        <div>
                            <select
                                value={selectedShift.key}
                                onChange={(e: any) => {
                                    setSelectedShift({ key: e.target.value });
                                    getNursesByDay(
                                        { key: e.target.value },
                                        selectedArea
                                    );
                                }}
                                className="MatutinoSelect"
                            >
                                {shifts.map((shift: any) => {
                                    return (
                                        <option
                                            key={uuid()}
                                            value={shift.schedule_name}
                                        >
                                            {`${shift.schedule_name.toUpperCase()}: [${
                                                shift.entrance_time
                                            } - ${shift.departure_time}]`}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div>
                            <select
                                value={selectedArea.key}
                                onChange={(e: any) => {
                                    let selectedAreaaux = e.target.value;
                                    let patientsaux =
                                        selectedAreaaux === "all"
                                            ? allPatients
                                            : allPatients.filter(
                                                  (p: any) =>
                                                      p.area_id ===
                                                      selectedAreaaux
                                              );
                                    selectedAreaaux = areas.find((area: any) => area.key === e.target.value);
                                    if (patientsaux.length === 0) {
                                        toast("No hay pacientes en este sector", {
                                            type: "error",
                                            containerId: "CSV",
                                        });
                                        patientsaux = allPatients;
                                        selectedAreaaux = areas.find((area: any) => area.key === "all");
                                    }
                                    setPatients(patientsaux);
                                    getNursesByDay(selectedShift, selectedAreaaux);
                                }}
                                className="TodosSelect"
                            >
                                {areas
                                .sort((a: any, b: any) => a.text.localeCompare(b.text) 
                                )
                                .map((area: any) => {
                                    return (
                                        <option key={uuid()} value={area.key}>
                                            {area.text}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div>
                            { canSave() &&
                            <button
                                disabled={!canSave()}
                                className="canAsignBtn"
                                onClick={() => onSave()}
                            >
                                <GuardarIcon
                                    style={{ marginRight: "10px" }}
                                ></GuardarIcon>
                                Asignar
                            </button>
                            || !canSave() &&
                            <button
                                disabled={!canSave()}
                                className="AsignBtn"
                                onClick={() => onSave()}
                            >
                                <GuardarIcon
                                    style={{ marginRight: "10px" }}
                                ></GuardarIcon>
                                Asignar
                            </button>
                            }
                        </div> 
                    </div>
                    <div
                        style={{
                            height: window.innerHeight - 200,
                            overflow: "auto",
                            scrollbarWidth: "thin",
                            marginTop: "2rem",
                        }}
                    >
                        <PatientShiftTable></PatientShiftTable>
                    </div>
                    <Backdrop open={showBackdrop}>
                        <CircularProgress style={{ color: " #62098c" }}></CircularProgress>
                    </Backdrop>
                    <ToastContainer
                    enableMultiContainer
                    containerId={"CSV"}
                    autoClose={2000}
                    position="top-right"
                    ></ToastContainer>
                </div>

                {/* <Drawer
                    anchor="right"
                    open={showProfessionalModal}
                    PaperProps={{
                        sx: {
                            width: "30%",
                            borderTopLeftRadius: "20px",
                        },
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "#62098C",
                            justifyContent: "end",
                            padding: "8%",
                        }}
                    >
                        <button
                            className="button-icon"
                            onClick={() => setShowProfessionalModal(false)}
                        >
                            <CrossIcon fill="white"></CrossIcon>
                        </button>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "#62098C",
                            color: "#FFFFFF",
                            height: "10%",
                            alignItems: "flex-end",
                            paddingLeft: "10%",
                            paddingBottom: "10%",
                            fontFamily: "Manrope",
                            fontWeight: "700",
                            fontSize: "16px",
                            lineHeight: "21.86px",
                        }}
                    >
                        Profesionales en turno
                    </div>
                    <div
                        style={{
                            padding: "6%",
                        }}
                    >
                        <table>
                            <tr>
                                <th className="TableTitle">Profesional</th>
                                <th></th>
                                <th className="TableTitle">Categoria</th>
                            </tr>
                            {availableNurses.map((nurse: any) => {
                                return (
                                    <tr key={uuid()}>
                                        <td>{nurse.nursename}</td>
                                        <td></td>
                                        <td style={{ textAlign: "center" }}>
                                            {nurse.classx}
                                        </td>
                                    </tr>
                                );
                            })}
                        </table>
                    </div>
                </Drawer> */}
            </>
        </ChangeShiftViewContext.Provider>
    );
}
