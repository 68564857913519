export interface ILoggedUser {
    id: string;
    fullName: string;
    job_title: string;
    email: string;
    type: number | undefined;
    nurse_id: string;
    areas: string[];
    imageURL: string;
    hospital_id: string;
    position: string;
}

export const isNurseAdmin = (position: string): boolean => {
    return position !== "nura_coord" && (position === "nura_jefe" || position === "nura_super");
};

export const emptyLoggedUser: ILoggedUser = {
    id: "",
    fullName: "",
    job_title: "",
    email: "",
    type: undefined,
    nurse_id: "",
    areas: [],
    imageURL: "",
    hospital_id: "",
    position: "",
};

export interface ISelectedPatient {
    // In DB, attentionId is patientid.
    id: string;
    name: string;
    dateAdmission: string;
    dateEgress: string | null;
    room: string | null;
    categoryId: string | null;
    categoryName: string | null;
    categoryMaxValue: string | null;
    area_id: string | null;
    areaName: string | null;
    nurseHistory: string[] | null;
    nurse_id: string | null;
    nurseName: string | null;
    attentionId: string | null;
    secondarynurse_id: string | null;
    secondaryNurseName: string | null;
}

export let emptySelectedPatient: ISelectedPatient = {
    id: "",
    name: "",
    dateAdmission: "",
    dateEgress: null,
    room: null,
    categoryId: null,
    categoryName: null,
    categoryMaxValue: null,
    area_id: null,
    areaName: null,
    nurseHistory: null,
    nurse_id: null,
    nurseName: null,
    attentionId: null,
    secondarynurse_id: null,
    secondaryNurseName: null,
};
